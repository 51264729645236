/*
* This file should include the imports to each indvidual js file you create plus some extras like jquery
* and the base foundation bits.
*/
import 'what-input';

// If you want all the foundation JS, uncomment the line below
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment the line below
import './lib/foundation-explicit-pieces';

import './lib/wp-foundation';

// Place Extra JS here
import './lib/product-accordion';
