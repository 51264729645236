import $ from 'jquery';
let accTriggers = document.querySelectorAll('.acc-trigger');

accTriggers.forEach(accTrigger => {
  accTrigger.addEventListener('click', () => {
    let accTriggerTarget = document.querySelector('#'+accTrigger.dataset.accordionId);
    accTriggerTarget.classList.toggle('active');
  })
});

var filters = {};

$(document).ready(function () {
  $('[id*="attribute-filter"').on('change', function () {
      var thisEl = this;
      var tax = thisEl.dataset.tax;
      filters[tax] = this.value;
      // var elems = this.value == 'unselected' ? $('.grid-item') : $('.grid-item[data-type~="'+this.value+'"]');
      // $('.grid-item').not(elems.show()).hide();
      taxFilter();
      console.log(filters);
  });
});

$( ".attribute-reset" ).click(function() {
  $('.grid-item').show();
  filters = {};
});

function taxFilter() {

  var items = document.querySelectorAll('.grid-item');
  items.forEach(item => {
    var elfilters = item.dataset.type;
    item.style.display = 'none';
    var doesItMatch = false;
    var doesItMatchArray = [];
    for(const property in filters){
      doesItMatchArray.push(elfilters.includes(filters[property]));
    }
    if(!doesItMatchArray.includes(false)) {
      item.style.display = 'grid';
    }
  });

  //var elems = filteredValues == '' ? $('.grid-item') : $('.grid-item[data-type~="'+filteredValues+'"]');
  //$('.grid-item').not(elems.show()).hide();
}

$(document).ready(function () {
  if ($(".product")[0]){
    $('.attributes').show();
    $('.widget_price_filter').show();
  } else {
    $('.attributes').hide();
    $('.widget_price_filter').hide();
  }
});
